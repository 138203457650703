import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

//默认路由
export const routes = [
  {
    path: "/",
    redirect: "/home",
  },
];

//pc端的路由
export const pcRoutes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/pc/pcHome.vue"),
  },
];

//移动端设备路由
export const mobileRoutes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/mobileHome.vue"),
  },
];

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    mode: "hash",
    routes: routes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
